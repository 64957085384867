import {useQuery} from '@apollo/client';

import {
    couponCategoriesV4,
    couponsScreenV4ExpiredQuery,
    couponsScreenV4LoadedQuery,
    couponsScreenV4Query,
    couponsScreenV4QueryWithoutFuelSaver,
    couponsScreenV4RedeemedQuery
} from '../../src/graphql/queries/coupons-queries';
import {CouponsScreenV4Query_couponsV4} from '../autogen/CouponsScreenV4Query';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {CouponCategoriesQueryV4_couponCategoriesV4} from '../autogen/CouponCategoriesQueryV4';
import {useCustomerContext} from '../contexts/customer-context';

import {useStore} from './use-store';

interface IUseCoupons {
    data: CouponsScreenV4Query_couponsV4[] | null;
    loading: boolean;
}

interface IUseCouponsCategories {
    data: CouponCategoriesQueryV4_couponCategoriesV4[] | null;
    loading: boolean;
}

const getQuery = (fuelSaverCardUuid: string, selectedOfferState: string) => {
    if (!fuelSaverCardUuid) {
        return couponsScreenV4QueryWithoutFuelSaver;
    }

    const queries = {
        Available: couponsScreenV4Query,
        Expired: couponsScreenV4ExpiredQuery,
        Loaded: couponsScreenV4LoadedQuery,
        Redeemed: couponsScreenV4RedeemedQuery
    };

    return queries[selectedOfferState];
};

const getConditionalVars = (fuelSaverCardUuid, storeId) => {
    if (fuelSaverCardUuid && storeId) {
        return {
            fuelSaverCardUuid,
            storeId: Number(storeId)
        };
    } else if (fuelSaverCardUuid) {
        return {fuelSaverCardUuid};
    } else if (storeId) {
        return {
            storeId: Number(storeId)
        };
    }

    return {};
};

export const useCoupons = (fuelSaverCardUuid: string, selectedOfferState: string): IUseCoupons => {
    const {customer} = useCustomerContext();
    const storeId = customer?.store?.storeId;
    const query = getQuery(fuelSaverCardUuid, selectedOfferState);
    const {data: storeData} = useStore();
    const selectedStoreId = storeData?.storeId;
    const conditionalVars = getConditionalVars(fuelSaverCardUuid, selectedStoreId || storeId);
    const {data, loading} = useQuery(query, {
        ssr: false,
        variables: {
            authType: fuelSaverCardUuid ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            ...conditionalVars
        }
    });

    return {
        data: data?.couponsV4 || null,
        loading
    };
};

export const useCouponsCategories = (): IUseCouponsCategories => {
    const {isAuthenticated, customer, loading: customerLoading} = useCustomerContext();
    const storeId = customer?.store?.storeId;
    const {data: storeData} = useStore();
    const selectedStoreId = storeData?.storeId;
    const conditionalVars = getConditionalVars(null, selectedStoreId || storeId);
    const {data, loading} = useQuery(couponCategoriesV4, {
        skip: customerLoading,
        ssr: false,
        variables: {
            authType: isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            ...conditionalVars
        }
    });

    return {
        data: data?.couponCategoriesV4 || null,
        loading: customerLoading || loading
    };
};
