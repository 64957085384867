import fetchService from './fetch-service';

export const loadCoupon = async (dealId: any) =>
    // eslint-disable-next-line no-return-await
    await fetchService(`/deals/api/deals/${dealId}/load`, {
        body: JSON.stringify({
            dealId
        }),
        method: 'POST'
    });

