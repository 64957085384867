import React from 'react';
import {GetServerSidePropsContext} from 'next';

import {useCouponsCategories} from '../../../hooks/use-coupons';
import {CouponsView} from '../../../components/coupons/coupons-view';
import GuestBanner from '../../../components/coupons/guest-banner';
import {PageHeader} from '../../../components/generic/PageHeader';

const breadCrumbPath = [
    {
        name: 'Home',
        url: '/deals'
    }, {
        name: 'Coupons'
    }
];

const CouponsPage = ({pageProps: {initialSelectedCategories}}: any) => {
    const {data: categories, loading: categoriesLoading} = useCouponsCategories();

    return (
        <>
            <GuestBanner />
            <PageHeader
                additionalContent={(
                    <p>
                        {'Saving is even easier with digital coupons. Swipe your Hy-Vee PERKS'}
                        <sup>&reg;</sup>
                        {' card at checkout to redeem. Hurry! Limited coupon quantities available.'}
                    </p>
                )}
                breadCrumbPath={breadCrumbPath}
                heading="Coupons"
            />
            <CouponsView
                categories={categories}
                categoriesLoading={categoriesLoading}
                initialSelectedCategories={initialSelectedCategories}
            />
        </>
    );
};

CouponsPage.wantsCustomerContext = () => true;

export const getServerSideProps = ({query}: GetServerSidePropsContext) => {
    const {catFilter = [], couponId} = query;

    if (couponId) {
        return {
            redirect: {
                destination: `/deals/coupons/${couponId}`,
                permanent: false
            }
        };
    }

    return {
        props: {
            initialSelectedCategories: [...Array.isArray(catFilter) ? catFilter : [catFilter]]
        }
    };
};

export default CouponsPage;
