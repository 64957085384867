import React from 'react';
import {Text} from '@hy-vee/design-system';

import {BreadCrumb} from '../controls/bread-crumb';

import styles from './generic.module.css';

export const PageHeader = ({breadCrumbPath, heading, additionalContent = null}) => (
    <div className={styles.headerContainer}>
        <BreadCrumb path={breadCrumbPath} />
        <Text
            data-testid="heading"
            textStyle="heading1"
        >
            {heading}
        </Text>
        {additionalContent}
    </div>
);
