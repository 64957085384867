import React, {FC} from 'react';
import getConfig from 'next/config';
import {useRouter} from 'next/router';
import {WebComponents} from '@hy-vee/react-web-and-mobile-ui-components';
// eslint-disable-next-line no-duplicate-imports
import type {IContextProps} from '@hy-vee/react-web-and-mobile-ui-components';

import {useCoupons} from '../../hooks/use-coupons';
import {login} from '../../utils/navigation-helpers';
import {useCustomerContext} from '../../contexts/customer-context';
import {sortCoupons} from '../../helpers/coupons-helpers';
import {clipCoupon, goToHyVeeUrl, seeDetails} from '../../utils/callback-helpers';

import styles from './coupons-grid.module.css';
import {ICategoriesState} from './coupons-view';

interface ICouponsGridProps {
    categoriesState: ICategoriesState;
    fuelSaverCardUuid: string | undefined;
    selectedCouponsSort: string;
    selectedOffersState: string;
}

export const CouponsGrid: FC<ICouponsGridProps> = ({categoriesState, selectedOffersState, fuelSaverCardUuid, selectedCouponsSort}) => {
    const router = useRouter();
    const {isAuthenticated, hasHPlusMembership, loading: customerLoading} = useCustomerContext();

    const {data: coupons, loading: couponsLoading} = useCoupons(fuelSaverCardUuid, selectedOffersState);
    const contextProps: IContextProps & { userFlags: { hasHPlusMembership?: boolean } } = {
        callbacks: {
            clipCallback: clipCoupon,
            getHyVeeBaseUrl: () => getConfig().publicRuntimeConfig.hyveeBaseUrl,
            goToHyVeeUrlCallback: goToHyVeeUrl,
            handleProductTabsChange: null,
            loginCallback: () => login(router.asPath),
            registerCallback: () => router.push('/my-account/sign-up?redirect=/deals/coupons'),
            seeAdDealDetailsCallback: null,
            seeDetailsCallback: (couponId, itemType) => seeDetails(couponId, itemType, router)
        },
        userFlags: {
            hasFuelSaver: Boolean(fuelSaverCardUuid),
            hasHPlusMembership,
            hasWebkitSupport: true,
            isAuthenticated
        }
    };

    if (couponsLoading || customerLoading) {
        return (
            <div
                className="loading-div"
                data-testid="couponLoadingDiv"
            >
                <img
                    alt="Loading"
                    src="https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/images/ajax-loader.gif"
                />
            </div>
        );
    }

    const sortedCoupons = sortCoupons(coupons, selectedCouponsSort);
    const filteredCoupons = sortedCoupons.filter((coupon) =>
        Object.keys(categoriesState).some((category) => categoriesState[category].checked)
            ? categoriesState[coupon.category]
            && categoriesState[coupon.category].checked
            : true);

    return (
        <div>
            <div className={filteredCoupons.length ? 'coupons-row' : 'coupons-row-no-coupons'}>
                {filteredCoupons.length ?
                    filteredCoupons
                        .map((coupon) =>
                            <div
                                className={styles.couponCardContainer}
                                key={coupon.couponId}
                            >
                                <WebComponents.CouponProductCard
                                    __typename="CouponV4"
                                    contextProps={contextProps}
                                    {...coupon}
                                />
                            </div>
                        )
                    :
                    <span>
                        {'No coupons to display.'}
                    </span>}
            </div>
        </div>
    );
};
