import {useEffect} from 'react';

export const useEscapeKey = (escHandler: () => void) => {
    useEffect(() => {
        const escFunction = (event) => {
            if (event.keyCode === 27) {
                escHandler();
            }
        };

        document.addEventListener('keydown', escFunction);

        return () => {
            document.removeEventListener('keydown', escFunction);
        };
    }, [escHandler]);
};
