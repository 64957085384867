import React, {FC} from 'react';
import Link from 'next/link';

import styles from './bread-crumb.module.css';

export interface IBreadCrumbElement {
    name: string;
    url?: string;
}

export interface IBreadCrumbProps {
    path: IBreadCrumbElement[];
}

export const BreadCrumb: FC<IBreadCrumbProps> = ({path}) => (
    <ul
        className={styles.breadCrumb}
        data-testid="bread-crumb"
    >
        {path.map((pathElement) => (
            <li
                className={styles.breadCrumbElement}
                data-testid="bread-crumb-element"
                key={pathElement.name}
            >
                {
                    pathElement.url ?
                        <Link href={pathElement.url}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a>{pathElement.name}</a>
                        </Link> :
                        pathElement.name
                }
            </li>
        ))}
    </ul>
);
