import React from 'react';
import {useRouter} from 'next/router';
import getConfig from 'next/config';

import {useCustomerContext} from '../../contexts/customer-context';
import {getLoginUrl, getSignUpUrl} from '../../utils/redirect-utils';

const GuestBanner = () => {
    const router = useRouter();
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;
    const {isAuthenticated, loading: customerLoading} = useCustomerContext();

    if (isAuthenticated || customerLoading) {
        return null;
    }

    const loginUrl = getLoginUrl(router.asPath);
    const signUpUrl = getSignUpUrl();
    const perksUrl = `${hyveeBaseUrl}/perks/`;
    const storesUrl = `${hyveeBaseUrl}/stores/`;

    return (
        <div
            className="coupon-guest-banner"
            data-testid="coupon-guest-banner"
        >
            <div className="coupon-guest-banner-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a
                    aria-hidden="true"
                    className="coupon-guest-banner-item-icon account-icon"
                    data-testid="coupon-guest-banner-item-icon-login-anchor"
                    href={loginUrl}
                />
                <div className="coupon-guest-banner-item-content">
                    <div className="coupon-guest-banner-item-h5">
                        {'1. Log In'}
                    </div>
                    <p>
                        <a
                            data-testid="coupon-guest-banner-login-anchor"
                            href={loginUrl}
                        >
                            {'Log in'}
                        </a>
                        {' using your My Hy-Vee account.'}
                        {/* eslint-disable-next-line react/forbid-elements */}
                        <br className="content-break" />
                        {' Don’t have one? '}
                        <a
                            data-testid="coupon-guest-banner-signup-anchor"
                            href={signUpUrl}
                        >
                            {'Sign up now'}
                        </a>
                        {' to'}
                        {/* eslint-disable-next-line react/forbid-elements */}
                        <br className="content-break" />
                        {' access coupons and more.'}
                    </p>
                </div>
            </div>
            <div className="coupon-guest-banner-item">
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a
                    aria-hidden="true"
                    className="coupon-guest-banner-item-icon card-icon"
                    data-testid="coupon-guest-banner-item-icon-perks-anchor"
                    href={perksUrl}
                />
                <div className="coupon-guest-banner-item-content">
                    <div className="coupon-guest-banner-item-h5">
                        {'2. Link Your Perks Card'}
                    </div>
                    <p>
                        {'Get more deals with your '}
                        <a
                            data-testid="coupon-guest-banner-perks-anchor"
                            href={perksUrl}
                        >
                            {'Hy-Vee'}
                            {/* eslint-disable-next-line react/forbid-elements */}
                            <br className="content-break" />
                            {' PERKS®'}
                        </a>
                        {' card. Don’t have'}
                        {/* eslint-disable-next-line react/forbid-elements */}
                        <br className="content-break" />
                        {' one? Pick one up at a '}
                        <a
                            data-testid="coupon-guest-banner-stores-anchor"
                            href={storesUrl}
                        >
                            {'store near you.'}
                        </a>
                    </p>
                </div>
            </div>
            <div className="coupon-guest-banner-item">
                <div className="coupon-guest-banner-item-icon clip-icon" />
                <div className="coupon-guest-banner-item-content">
                    <div className="coupon-guest-banner-item-h5">
                        {'3. Start Clipping!'}
                    </div>
                    <p>
                        {'Clip digital coupons to your '}
                        {/* eslint-disable-next-line react/forbid-elements */}
                        <br className="content-break" />
                        {'Hy-Vee PERKS® card. '}
                        {/* eslint-disable-next-line react/forbid-elements */}
                        <br className="content-break" />
                        {'Scan at checkout and save.'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default GuestBanner;
