import getConfig from 'next/config';
import {NextRouter} from 'next/router';
import {ItemTypes} from '@hy-vee/react-web-and-mobile-ui-components';

import {loadCoupon} from '../services/deals-service';

import {navigate} from './navigation-helpers';

export const clipCoupon = (dealId: any, setState: any) => async () => {
    let clipped = false;

    try {
        setState('loading');
        await loadCoupon(dealId);
        setState('Activated');

        clipped = true;
    } catch {}

    return clipped;
};

export const goToHyVeeUrl = (endOfUrl: any, router?: NextRouter) => navigate(`${getConfig().publicRuntimeConfig.hyveeBaseUrl}${endOfUrl}`, router);

export const seeCouponDetails = (couponId: any, router?: NextRouter) => navigate(`/deals/coupons/${couponId}`, router);

export const seeAdDealDetails = (dealId: any, router?: NextRouter) => navigate(`${getConfig().publicRuntimeConfig.hyveeBaseUrl}/deals/${dealId}`, router);

export const seeCashBackOfferDetails = (cashBackOfferId: any, router?: NextRouter) => navigate(`/deals/cash-back/${cashBackOfferId}`, router);

export const seeDetails = (itemId: any, itemType: ItemTypes, router?: NextRouter) => itemType === ItemTypes.COUPON ? seeCouponDetails(itemId, router) : seeCashBackOfferDetails(itemId, router);
