import gql from 'graphql-tag';

const couponFragmentV4 = gql`
    fragment ICouponV4 on CouponV4 {
        couponId
        clipStartDate
        category
        imageUrl
        brand
        clipEndDate
        expirationDate
        value
        valueText
        description
        terms
        offerState
        upcs
        redeemedDate
    }
`;

export const couponsScreenV4QueryWithoutFuelSaver = gql`
    ${couponFragmentV4}
    
    query CouponsScreenV4QueryWithoutFuelSaver ($storeId: Int) {
        couponsV4 (storeId: $storeId) {
            ...ICouponV4
        }
    }
`;

export const couponsScreenV4Query = gql`
    ${couponFragmentV4}

    query CouponsScreenV4Query ($fuelSaverCardUuid: String!, $storeId: Int) {
        couponsV4 (fuelSaverCardUuid: $fuelSaverCardUuid, storeId: $storeId) {
            ...ICouponV4
        }
    }
`;

export const getCouponDetails = gql`
    ${couponFragmentV4}
    query GetCouponDetails($couponId: ID!) {
        couponV4(couponId: $couponId) {
            ...ICouponV4
        }
    }
`;

export const couponCategoriesV4 = gql`
    query CouponCategoriesQueryV4 ($storeId: Int) {
        couponCategoriesV4 (storeId: $storeId) {
            categoryName
            count
        }
    }
`;

export const couponsScreenV4ExpiredQuery = gql`
    ${couponFragmentV4}

    query CouponsScreenV4ExpiredQuery ($fuelSaverCardUuid: String!) {
        couponsV4 (fuelSaverCardUuid: $fuelSaverCardUuid, offerStates: ["expired"]) {
            ...ICouponV4
        }
    }
`;

export const couponsScreenV4LoadedQuery = gql`
    ${couponFragmentV4}

    query CouponsScreenV4LoadedQuery ($fuelSaverCardUuid: String!) {
        couponsV4 (fuelSaverCardUuid: $fuelSaverCardUuid, offerStates: ["loaded"]) {
            ...ICouponV4
        }
    }
`;

export const couponsScreenV4RedeemedQuery = gql`
    ${couponFragmentV4}

    query CouponsScreenV4RedeemedQuery ($fuelSaverCardUuid: String!) {
        couponsV4 (fuelSaverCardUuid: $fuelSaverCardUuid, offerStates: ["redeemed"]) {
            ...ICouponV4
        }
    }
`;
