import React, {FC} from 'react';
import {Select} from '@hy-vee/web-core';

interface ICouponsSortProps {
    alignRow: boolean;
    selectedCouponsSort: string;
    setSelectedCouponsSort: React.Dispatch<React.SetStateAction<string>>;
}

const sortOptions = [
    {
        label: 'Most Relevant',
        value: 'relevant'
    }, {
        label: 'Newest',
        value: 'desc'
    }, {
        label: 'Brand',
        value: 'brand'
    }, {
        label: 'Value',
        value: 'value'
    }, {
        label: 'Expiration',
        value: 'expirationDate'
    }
];

export const CouponsSort: FC<ICouponsSortProps> = ({alignRow, selectedCouponsSort, setSelectedCouponsSort}) => {
    const sortChanged = (selection) => {
        setSelectedCouponsSort(selection.value);
    };

    const reactSelectStyles = {
        control: (provided) => ({
            ...provided,
            'minHeight': 0
        }),
        singleValue: (provided) => ({
            ...provided,
            'fontSize': '14px'
        })
    };

    return (
        <Select
            alignRow={alignRow}
            className="coupons-sort-select"
            classNamePrefix="coupons-sort-select"
            data-testid="couponSortSelect"
            isSearchable={false}
            label="Sort By"
            margin={0}
            onChange={sortChanged}
            options={sortOptions}
            styles={reactSelectStyles}
            value={selectedCouponsSort}
        />
    );
};
