import React, {FC, useEffect, useState} from 'react';
import {OutlineButton, ToggleButtonGroup} from '@hy-vee/web-core';
import {useRouter} from 'next/router';

import {useScreenSize} from '../../contexts/screen-context';
import {COUPONS_MOBILE_SCREEN_WIDTH} from '../../enums/media-sizes';
import {CouponCategoriesQueryV4_couponCategoriesV4} from '../../autogen/CouponCategoriesQueryV4';
import {useEscapeKey} from '../../hooks/use-keys';
import {
    OFFER_STATE_AVAILABLE,
    OFFER_STATE_EXPIRED,
    OFFER_STATE_LOADED,
    OFFER_STATE_REDEEMED
} from '../../enums/offer-state-enums';
import {COUPON_SORT_MAP} from '../../enums/coupon-sorting-enums';
import {isServer} from '../../utils/window';
import {useCustomerContext} from '../../contexts/customer-context';
import {navigate} from '../../utils/navigation-helpers';

import {CouponCategories} from './coupon-categories';
import {CouponsGrid} from './coupons-grid';
import {CouponsSort} from './coupons-sort';
import {PrintableCouponsBox} from './printable-coupons-box';

export interface ICategoriesState {
    [key: string]: {
        categoryName: string;
        count: number;
        checked: boolean;
    };
}

const getOfferStatesForFuelSaver = (fuelSaverCardUuid) => fuelSaverCardUuid ?
    [
        OFFER_STATE_AVAILABLE,
        OFFER_STATE_LOADED,
        OFFER_STATE_EXPIRED,
        OFFER_STATE_REDEEMED
    ] : [
        OFFER_STATE_AVAILABLE
    ];

interface ICouponsViewProps {
    categories: CouponCategoriesQueryV4_couponCategoriesV4[];
    categoriesLoading: boolean;
    initialSelectedCategories: string[];
}

const onHandleSlideOut = (showSlideOut, setShowSlideOut) => {
    setShowSlideOut(!showSlideOut);
};

export const CouponsView: FC<ICouponsViewProps> = ({
    categories,
    categoriesLoading,
    initialSelectedCategories
}) => {
    const router = useRouter();
    const {screenWidth} = useScreenSize();
    const {loading: customerLoading, customer} = useCustomerContext();
    const fuelSaverCardUuid = customer?.fuelSaverCard?.fuelSaverCardUuid;
    const routeOfferState = [router.query.offerState].flat()[0] || OFFER_STATE_AVAILABLE;

    const [categoriesState, setCategoriesState] = useState<ICategoriesState>({});
    const [selectedOfferState, setSelectedOfferState] = useState(routeOfferState);
    const [displayableOfferStates, setDisplayableOfferStates] = useState(getOfferStatesForFuelSaver(fuelSaverCardUuid));
    const [offerStateTabNames, setOfferStateTabNames] = useState([]);
    const [selectedCouponsSort, setSelectedCouponsSort] = useState(COUPON_SORT_MAP.MOST_RELEVANT);
    const [showSlideOut, setShowSlideOut] = useState(false);

    const onChangeSelectedOffersState = (event) => setSelectedOfferState(event.target.value);

    if (!isServer() && router.query.offerState !== selectedOfferState) {
        router.replace({
            pathname: router.pathname,
            query: {
                ...router.query,
                offerState: selectedOfferState
            }
        }, undefined, {
            shallow: true
        });
    }

    useEffect(() => {
        if (categories) {
            const filteredCategories = categories.filter((category) => category.categoryName !== 'Alcohol' || customer);

            setCategoriesState(filteredCategories.reduce((accum, category) => ({
                ...accum,
                [category.categoryName]: {
                    ...category,
                    checked: initialSelectedCategories.includes(category.categoryName)
                }
            }), {}));
        }
    }, [categories, initialSelectedCategories, customer]);

    useEffect(() => {
        setDisplayableOfferStates(getOfferStatesForFuelSaver(fuelSaverCardUuid));
    }, [fuelSaverCardUuid]);

    useEffect(() => {
        const tabNames = displayableOfferStates.map((offerState, index) => ({
            id: index,
            label: screenWidth <= COUPONS_MOBILE_SCREEN_WIDTH ? offerState : `${offerState} Coupons`,
            value: offerState
        }));

        setOfferStateTabNames(tabNames);
    }, [screenWidth, displayableOfferStates]);

    useEffect(() => {
        if (!customerLoading && !displayableOfferStates.includes(selectedOfferState)) {
            setSelectedOfferState(OFFER_STATE_AVAILABLE);
        }
    }, [customerLoading, selectedOfferState, displayableOfferStates]);

    useEscapeKey(() => {
        if (showSlideOut) {
            setShowSlideOut(false);
        }
    });

    return (
        <div
            className="coupon-grid-container"
            data-testid="coupons-view"
        >
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
                aria-label="Close Filter & Sort"
                className={screenWidth <= COUPONS_MOBILE_SCREEN_WIDTH && showSlideOut ? 'disabled-coupons-page-active' : 'disabled-coupons-page'}
                data-testid="disableCouponsPage"
                onClick={() => onHandleSlideOut(showSlideOut, setShowSlideOut)}
                type="button"
            />
            <div
                className="coupon-tabs"
                data-testid="couponTabs"
            >
                <ToggleButtonGroup
                    currentValue={selectedOfferState}
                    hideHeader
                    marginBottom={0}
                    onChange={onChangeSelectedOffersState}
                    options={offerStateTabNames}
                />
                <div
                    className="filter-and-sort-button"
                >
                    <OutlineButton
                        data-testid="filterAndSort"
                        onClick={() => onHandleSlideOut(showSlideOut, setShowSlideOut)}
                    >
                        {'Filter & Sort'}
                    </OutlineButton>
                </div>
                <div className="coupons-desktop-sorter">
                    {selectedOfferState === OFFER_STATE_LOADED ?
                        <div className="print-loaded-coupons-button-wrapper">
                            <OutlineButton
                                className="print-loaded-coupons-button"
                                data-testid="printLoadedCoupons"
                                disabled={categoriesLoading}
                                onClick={() => navigate('/deals/print-loaded-coupons', router)}
                            >
                                <span>{'Print Loaded Coupons'}</span>
                            </OutlineButton>
                        </div> : null}
                    <CouponsSort
                        alignRow
                        selectedCouponsSort={selectedCouponsSort}
                        setSelectedCouponsSort={setSelectedCouponsSort}
                    />
                </div>
            </div>
            <div className="coupons-grid">
                <div
                    className={screenWidth <= COUPONS_MOBILE_SCREEN_WIDTH && showSlideOut ? 'slideOut showSlideOut' : 'slideOut'}
                    data-testid="couponCategorySlider"
                >
                    <div className="coupon-categories-slider">
                        {!categoriesLoading &&
                            <CouponCategories
                                categoriesState={categoriesState}
                                selectedOffersState={selectedOfferState}
                                setCategoriesState={setCategoriesState}
                            />}
                        <CouponsSort
                            alignRow={false}
                            selectedCouponsSort={selectedCouponsSort}
                            setSelectedCouponsSort={setSelectedCouponsSort}
                        />
                    </div>
                </div>
                <div
                    className="coupons-column left coupon-categories-non-slider"
                    data-testid="couponCategoryContainer"
                >
                    {!categoriesLoading &&
                        <CouponCategories
                            categoriesState={categoriesState}
                            selectedOffersState={selectedOfferState}
                            setCategoriesState={setCategoriesState}
                        />}
                    <PrintableCouponsBox />
                </div>
                <div className="coupons-column coupon-grid">
                    <CouponsGrid
                        categoriesState={categoriesState}
                        fuelSaverCardUuid={fuelSaverCardUuid}
                        selectedCouponsSort={selectedCouponsSort}
                        selectedOffersState={selectedOfferState}
                    />
                    <div className="printable-coupons-box-mobile">
                        <PrintableCouponsBox />
                    </div>
                </div>
            </div>
        </div>
    );
};
