import {COUPON_SORT_MAP} from '../enums/coupon-sorting-enums';

export const sortCoupons = (coupons, selectedCouponsSort) => {
    const sortedCoupons = [...coupons];

    switch (selectedCouponsSort) {
        case COUPON_SORT_MAP.EXPIRATION_DATE: {
            return sortedCoupons.sort((a, b) => Date.parse(a.expirationDate) - Date.parse(b.expirationDate));
        }

        case COUPON_SORT_MAP.OFFER_BRAND: {
            return sortedCoupons.sort((a, b) => a.brand.localeCompare(b.brand));
        }

        case COUPON_SORT_MAP.OFFER_VALUE: {
            return sortedCoupons.sort((a, b) => b.value - a.value);
        }

        case COUPON_SORT_MAP.NEW_COUPONS: {
            return sortedCoupons.sort((a, b) => Date.parse(b.expirationDate) - Date.parse(a.expirationDate));
        }

        default:
            return sortedCoupons;
    }
};
