import React, {FC, useState} from 'react';
import {CaretDownIcon, CaretRightIcon} from '@hy-vee/icons';

import {OFFER_STATE_AVAILABLE} from '../../enums/offer-state-enums';

import {ICategoriesState} from './coupons-view';

interface ICouponCategoriesProps {
    categoriesState: ICategoriesState;
    selectedOffersState: string;
    setCategoriesState: React.Dispatch<React.SetStateAction<ICategoriesState>>;
}

export const CouponCategories: FC<ICouponCategoriesProps> = ({categoriesState, setCategoriesState, selectedOffersState}) => {
    const [categoryListCollapsed, setCategoryListCollapsed] = useState(false);
    const checkedChanged = (event) => {
        setCategoriesState({
            ...categoriesState,
            [event.target.name]: {
                ...categoriesState[event.target.name],
                checked: event.target.checked
            }
        });
    };

    return (
        <div
            className="coupon-category-filter"
            style={{
                border: '1px solid #DDD',
                fontSize: '14px',
                padding: '10px 12px',
                width: '220px'
            }}
        >
            <button
                aria-expanded={!categoryListCollapsed}
                data-testid="expanderButton"
                onClick={() => setCategoryListCollapsed(!categoryListCollapsed)}
                style={{
                    alignItems: 'center',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    fontWeight: 500,
                    justifyContent: 'space-between',
                    margin: 0,
                    padding: '0 0 5px 0',
                    width: '100%'
                }}
                type="button"
            >
                <div
                    style={{
                        paddingTop: '2px',
                        textTransform: 'uppercase'
                    }}
                >
                    {'Categories'}
                </div>
                <div
                    style={{
                        display: 'inline-block'
                    }}
                >
                    {categoryListCollapsed ? <CaretRightIcon size="small" /> : <CaretDownIcon size="small" />}
                </div>
            </button>
            <ul
                className={`${categoryListCollapsed ? 'coupon-category-filter-collapsed' : 'coupon-category-filter-open'}`}
                data-testid="categoryList"
            >
                {Object.keys(categoriesState).map((categoryKey) => {
                    const category = categoriesState[categoryKey];

                    return (
                        <li key={category.categoryName}>
                            <label>
                                <input
                                    checked={category.checked}
                                    name={category.categoryName}
                                    onChange={checkedChanged}
                                    type="checkbox"
                                />
                                {selectedOffersState === OFFER_STATE_AVAILABLE ? `${category.categoryName} (${category.count})` : `${category.categoryName}`}
                            </label>
                        </li>);
                })}
            </ul>
        </div>
    );
};
