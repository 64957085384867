import React, {FC} from 'react';

export const PrintableCouponsBox: FC = () => (
    <div className="printable-coupons-box">
        <h4 className="printable-coupons">{'Printable Coupons'}</h4>
        <p>{'Browse, print and save. More coupons available at:'}</p>
        <p className="printable-coupons-image">
            <a
                href="https://www.coupons.com/"
                rel="noreferrer"
                target="_blank"
            >
                <img
                    alt="coupons.com"
                    src="https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/deals/images/coupons_logo160x100.png"
                    width="100"
                />
            </a>
        </p>
    </div>);
